import { HTTP_METHOD_TYPE } from '../utils/constants';
import { pageAccountOrders, pageAccountOrder } from '../utils/adaptors';

const PER_PAGE = 10;
const PAGE = 1;

export async function fetchOrdersService({ $http, params }) {
    Object.assign(params, {
        'per-page': PER_PAGE,
        page: PAGE,
        ...params,
    });

    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}user/orders`,
        params,
    });

    const items = response.data.items.map((item) => pageAccountOrders(item));
    const { filters } = response.data;
    const pagination = {
        current: params.page,
        perPage: params['per-page'],
        rows: response.data.page_info.total_records,
        totalPages: response.data.page_info.total_pages,
    };

    const result = {
        pagination,
        items,
        filters,
    };

    return result;
}

export async function fetchOrderService({ $http, params, orderId }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}user/order/${orderId}`,
        params,
    });

    return pageAccountOrder(response.data);
}

export async function cancelOrderService({
    $http,
    params, orderId, data,
}) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.PATCH,
        url: `${$http.API.V2}user/order/${orderId}/cancel`,
        data,
        params,
    });

    return response.data;
}

export async function modifyOrderAddressService({ $http, params, data }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.PATCH,
        url: `${$http.API.V1}order`,
        data,
        params,
    });

    return response.data;
}

export async function downloadDocumentService({ $http, url }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url,
        responseType: 'blob',
    });

    return new Blob([response.data]);
}

export async function fetchOrderChatService({ $http, params, orderId }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}user/chat/order/${orderId}`,
        params,
    });

    return response.data.data;
}

export async function sendOrderChatMessageService({
    $http,
    params,
    orderId,
    data,
}) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.POST,
        url: `${$http.API.V3}user/chat/order/${orderId}`,
        data,
        params,
    });

    return response.data;
}
