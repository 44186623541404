import { HTTP_METHOD_TYPE } from '../utils/constants';

export async function fetchPageAccountWalletService({ $http, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}user/wallet/balance`,
        params,
    });

    return response.data;
}

export async function fetchPageAccountWalletTransactionsService({ $http, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}user/wallet/transactions`,
        params,
    });

    return response.data;
}

export async function fetchPageAccountWalletEarnInstructionsService({ $http, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}user/wallet/rewards/earn-instructions`,
        params,
    });

    return response.data;
}
